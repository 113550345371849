import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import funs from './utils/fun.js'
import model from './plugins/modal.js'
import './config/rem'//rem布局

/* pdf预览 */
// import pdf from 'vue-pdf'
// Vue.component('pdf', pdf);
/* 分页 */
// 文件上传组件
import FileUpload from "@/components/FileUpload"
Vue.component('FileUpload', FileUpload)
import pagination from '@/components/pagination/index';
Vue.component('pagination', pagination);
/* 地址库 */
import AddressSel from '@/components/address/index.vue'
Vue.component('AddressSel', AddressSel);
import '@/styles/index.scss'
import '@/icons' // icon
import '@/permission'
import $ from "jquery";
import '@/utils/tap/drag';//拖拽
import '@/utils/tap/dialogDrag';//拖拽



window.jQuery = $;
window.$ = $;
Vue.prototype.$EventBus = new Vue();
import EventBus from "./utils/even_bus.js";
//引入
import socketPublic from '@/utils/websocket.js'
import billPrint from "@/components/billPrint/index"; //打印
Vue.component('billPrint', billPrint)
// import pdfView from "@/components/pdf/pdfView"; //pdf预览
import pdfView from '@/components/pdf/index'


Vue.component('pdfView', pdfView)

import Print from "vue-print-nb";
Vue.use(Print);
import {
	download
} from '@/utils/request'

//挂载
Vue.prototype.$download = download
Vue.prototype.$socketPublic = socketPublic
Vue.prototype.$evenbus = EventBus;
Vue.prototype.$funs = funs;
Vue.prototype.$model = model;
Vue.use(ElementUI);
Vue.config.productionTip = false

/* IP */
Vue.prototype.$baseUrl = 'http://192.168.0.146:9001';

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')